export const getAnimation = ({ value, animateHeader, height, bg }) => {
  if (value === 'fixed') {
    return { style: {}, tailwind: 'fixed fixed z-40' }
  } else if (value === 'opacity') {
    return {
      style: { opacity: animateHeader ? 0 : 1 },
      tailwind: 'fixed fixed z-40 transition ease-in-out duration-500',
    }
  } else if (value === 'position') {
    return {
      style: { top: animateHeader ? -height : 0 },
      tailwind: 'fixed fixed z-40 transition-all ease-out duration-300',
    }
  } else if (value === 'transparent') {
    return {
      style: { backgroundColor: animateHeader ? bg : 'transparent' },
      tailwind: 'fixed fixed z-40 transition ease-in-out duration-250',
    }
  } else {
    return { style: {}, tailwind: 'relative' }
  }
}
