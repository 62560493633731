import { getMappedSizeNameFromItem } from './getMappedSizeName'

export const getProductCartDescription = (item) => {
  let desc = item?.product?.name
  if (item?.product?.variantName?.length > 0) {
    desc += `, ${item?.product.variantName}`
  }
  if (item?.size?.length > 0) {
    desc += `, ${getMappedSizeNameFromItem({ item })}`
  }

  if (item?.subscriptionPlan) {
    desc += `, (${item?.subscriptionPlan.name})`
  }
  return desc
}
