import { useCallback, useEffect, useState } from 'react'
import { useRouter } from 'next/router'
import { isServer } from '../helpers/isServer'

export const useScrollDownAnimate_transparent = ({
  thresholdHeight = 60,
  top = 0,
}) => {
  const [animateHeader, setAnimateHeader] = useState(false)
  const router = useRouter()
  const [y, setY] = useState(isServer ? 0 : window.scrollY)

  const handleNavigation = useCallback(
    (e) => {
      if (y > window.scrollY && top === 0) {
        // SCROLL UP
        setAnimateHeader(false)
      } else if ((top > 0 && y < top) || window.scrollY === 0) {
        // If top is greater then 0 we know we dont want to
        // have the scroll up animation
        // only when we are at the top 🫶
        setAnimateHeader(false)
      } else if (y > thresholdHeight && y < window.scrollY) {
        // SCROLL DOWN
        setAnimateHeader(true)
      } else if (window.scrollY !== 0) {
        setAnimateHeader(true)
      }
      setY(window.scrollY)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [y, thresholdHeight],
  )
  useEffect(() => {
    handleNavigation()
  }, [])

  useEffect(() => {
    window.addEventListener('scroll', handleNavigation)
    router.events.on('routeChangeComplete', handleNavigation)

    return () => {
      window.removeEventListener('scroll', handleNavigation)
      router.events.off('routeChangeComplete', handleNavigation)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [handleNavigation])

  return [animateHeader]
}
