import React, { useContext, useEffect, useState } from 'react'
import useFindify from '@findify/react-bundle'
import { isServer } from '../../helpers/isServer'
import { ProductGrid } from '../page_types/category/ProductGrid'
import { CartStoreContext } from '../../store/cartStore'
import { SideModal } from '../sharable/modals/SideModal'
import { SizePicker } from '../sharable/sizePicker/SizePicker'
import { Loading } from '../atoms/Loading'
import { showWarningAlert } from '../../helpers/alert'
import { observer } from 'mobx-react'
import { useRouter } from 'next/router'
import { isItemInStock } from '../../helpers/isItemInStock'
import { getText } from '../../helpers/getText'

export const Findify = observer(({ data, blok }) => {
  const router = useRouter()

  // this is hax yes hmmmm so google bots can find product on server
  blok.product_card = [{ component: 'product_card_full_image' }]
  return (
    <>
      {isServer ? (
        blok?.type === 'smart-collection' ? (
          <ProductGrid blok={blok} data={data} />
        ) : null
      ) : (
        <>
          <Internal
            data={data}
            blok={blok}
            itemIds={
              data?.product?.centraVariant ? [data?.product?.centraVariant] : []
            }
            slot={
              blok?.type === 'smart-collection'
                ? data.locale + '/category/' + router.query?.category.join('/')
                : blok?.slot
                ? blok.slot
                : null
            }
          />
        </>
      )}
    </>
  )
})

export const useFindifyClick = (data) => {
  const { addToCart, fetchProductFromUri } = useContext(CartStoreContext)

  const [uri, setUri] = useState()
  const [show, setShow] = useState(false)
  const clickSize = async ({ id, size, url }) => {
    const uri = url?.split('product/')?.[1]
    if (!size) {
      setUri(uri)
      setShow(true)
    } else {
      const p = await fetchProductFromUri({ uri: uri })
      const item = p?.items.find(
        (i) => i.name.toLowerCase() === size.toLowerCase(),
      )
      if (!item || !isItemInStock(item.stock)) {
        showWarningAlert({
          title: '',
          message: blok?.not_in_stock_info
            ? getText(blok?.not_in_stock_info, data)
            : 'Product not in stock',
        })
      } else {
        await addToCart(item, p)
      }

      return p
    }
  }

  return [clickSize, show, uri, setShow]
}

const Internal = ({ blok, data, slot, itemIds }) => {
  const [clickSize, show, uri, setShow] = useFindifyClick(data)

  const [container, isReady, hasError, error] = useFindify({
    type: blok?.type ?? 'search',
    config: {
      ...(slot?.length > 0 ? { slot: slot } : {}),
      clickSize: clickSize,
    },
    options: {
      item_ids: itemIds,
    },
  })

  return (
    <>
      <div className={'w-full'} ref={container} />
      <FindifySizeSideModal
        blok={blok}
        data={data}
        uri={uri}
        show={show}
        onClose={() => {
          setShow(false)
        }}
      />
    </>
  )
}

export const FindifySizeSideModal = ({ blok, data, uri, show, onClose }) => {
  const { addToCart, fetchProductFromUri } = useContext(CartStoreContext)
  const [product, setProduct] = useState()
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if (show && uri?.length) {
      const fetch = async () => {
        setLoading(true)
        const p = await fetchProductFromUri({ uri })
        setProduct(p)
        setLoading(false)
      }
      fetch()
    }
  }, [uri, show, fetchProductFromUri])

  return (
    <SideModal
      title={
        blok?.size_picker_title
          ? getText(blok?.size_picker_title, data)
          : 'Pick a size'
      }
      show={show}
      onClose={() => {
        onClose()
      }}
    >
      <div className={'flex flex-col'}>
        {loading ? (
          <div className={'flex w-full justify-center'}>
            <Loading />
          </div>
        ) : (
          <SizePicker
            product={product}
            design={
              blok?.size_picker_style ? blok?.size_picker_style : 'dropdown'
            }
            onSizeClicked={async (size) => {
              setLoading(true)
              await addToCart(
                product?.items.find(
                  (item) =>
                    item.itemTableX === size.x && item.itemTableY === size.y,
                ),
                product,
              )
              setLoading(false)
              onClose()
            }}
          />
        )}
      </div>
    </SideModal>
  )
}

const tailwind =
  'flex-1 flex items-center justify-center bg-white hover:bg-gray-100 cursor-pointer lg:flex absolute right-0 left-0 w-full justify-center w-full flex-wrap pl-6 pr-6 hidden lg:hidden flex'
