import DynamicComponent from '../../DynamicComponent'

export const Footers = ({ data, preview }) => {
  const backgroundColor = data?.layoutData?.footer?.[0]?.background_color
    ? data?.layoutData?.footer[0].background_color
    : ''

  return (
    <div style={{ backgroundColor: backgroundColor }}>
      {data?.layoutData?.footer?.[0]?.bloks?.map((nestedBlok, index) => (
        <DynamicComponent
          key={index}
          blok={nestedBlok}
          data={data}
          preview={preview}
        />
      ))}
    </div>
  )
}
