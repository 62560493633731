import { observer } from 'mobx-react'
import { HeaderLinkStoreContext } from '../../../../store/headerLinkStore'
import classNames from 'classnames'
import { isServer } from '../../../../helpers/isServer'
import { Cart } from '../Cart'
import { useContext, useEffect, useState } from 'react'
import { useHeader_transparent } from './hooks/uesHeader_transparent'
import { HeaderChildren } from './HeaderChildren'
import { useRouter } from 'next/router'
import { isMobile } from 'react-device-detect'

export const TransparentHeaderAnimation = observer(
  ({ data, onHeight, blok, type }) => {
    const router = useRouter()
    const [tempHeight, setTempHeight] = useState(0)
    const isActive = () => {
      if (
        blok?.active_on_pages?.replaceAll(/\s/g, '')?.split(',')?.length > 0
      ) {
        const pages = blok?.active_on_pages?.replaceAll(/\s/g, '')?.split(',')
        return pages.findIndex((p) => p === router?.asPath) > -1
      }
      return false
    }
    const { setLink } = useContext(HeaderLinkStoreContext)
    const [hover, setHover] = useState(false)
    const [squareRef, height, anime, showCart, setShowCart, animateHeader] =
      useHeader_transparent({
        type: type,
        onHeight: (h) => {
          // Save in temp height so we can change header height when route change
          setTempHeight(h)
        },
        data,
      })

    useEffect(() => {
      if (isActive()) {
        onHeight(0)
      } else {
        onHeight(tempHeight)
      }
      // Change header padding/height when we change route
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [router.asPath, tempHeight])

    const getBGColor = () => {
      // If choosing transparent we always want to start with transparent
      // then we change to background color when scroll down 🫶
      if ((!hover && isActive()) || (isServer && isActive())) {
        return { backgroundColor: 'transparent' }
      }

      return {
        backgroundColor: data?.layoutData?.header_background_color
          ? data?.layoutData?.header_background_color
          : 'lightgray',
      }
    }

    const getInvert = () => {
      if (isServer && isActive()) {
        return 'invert '
      }
      if (!hover && !animateHeader && isActive()) {
        return 'invert '
      }
    }

    return (
      <div
        onMouseEnter={() => {
          if (!isMobile) {
            setHover(true)
            window.header_link_hax_show = true
          }
        }}
        onMouseLeave={() => {
          if (!isMobile) {
            setHover(false)
            window.header_link_hax_show = false
            setLink(null)
          }
        }}
        ref={squareRef}
        className={classNames(
          getInvert(),
          'w-screen bg-transparent',
          anime.tailwind,
        )}
        style={
          !isActive() || hover || isServer
            ? { ...getBGColor() }
            : {
                ...getBGColor(),
                ...anime.style,
              }
        }
      >
        <HeaderChildren data={data} height={height} />

        <Cart
          show={showCart}
          onClose={() => setShowCart(false)}
          settingsData={data?.settingsData}
          data={data}
        />
      </div>
    )
  },
)
