import { observer } from 'mobx-react'
import { HeaderLinkStoreContext } from '../../../../store/headerLinkStore'
import classNames from 'classnames'
import { Cart } from '../Cart'
import { HeaderChildren } from './HeaderChildren'
import { useContext } from 'react'
import { useHeader } from './hooks/useHeader'

export const DefaultHeaderAnimation = observer(({ data, onHeight, type }) => {
  const { setLink } = useContext(HeaderLinkStoreContext)
  const [squareRef, height, anime, showCart, setShowCart] = useHeader({
    type,
    onHeight,
    data,
  })

  return (
    <div
      onMouseEnter={() => {}}
      onMouseLeave={() => {
        setLink(null)
      }}
      ref={squareRef}
      className={classNames('w-screen', anime.tailwind)}
      style={{
        ...anime.style,
        backgroundColor: data?.layoutData?.header_background_color
          ? data?.layoutData?.header_background_color
          : 'lightgray',
      }}
    >
      <HeaderChildren data={data} height={height} />

      <Cart
        show={showCart}
        onClose={() => setShowCart(false)}
        settingsData={data?.settingsData}
        data={data}
      />
    </div>
  )
})
