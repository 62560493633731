import classNames from 'classnames'
import { allowedComponents } from '../../../../helpers/allowedComponents'
import { ComponentPlaceholder } from '../../../atoms/ComponentPlaceholder'

const Components = {
  ...allowedComponents.default_page,
}

const DynamicHeaderBlock = ({ blok, data, children }) => {
  if (typeof Components[blok.component] !== 'undefined') {
    const Component = Components[blok.component]
    return (
      <Component blok={blok} data={data}>
        {children}
      </Component>
    )
  }
  return <ComponentPlaceholder componentName={blok.component} />
}
export const HeaderChildren = ({ data, height }) => {
  return (
    <>
      <div className={'w-full lg:block hidden'}>
        {data?.layoutData?.header_desktop?.map((nestedBlok, index) => (
          <DynamicHeaderBlock
            key={index}
            blok={nestedBlok}
            data={{ ...data, headerHeight: height }}
          />
        ))}
      </div>
      <div
        className={classNames(
          'w-full',
          data?.layoutData?.header_desktop?.length === 0
            ? 'block'
            : 'lg:hidden block',
        )}
      >
        {data?.layoutData?.header_mobile?.map((nestedBlok, index) => (
          <DynamicHeaderBlock
            key={index}
            blok={nestedBlok}
            data={{ ...data, headerHeight: height }}
          />
        ))}
      </div>
    </>
  )
}
