import { CartStoreContext } from '../../../../../store/cartStore'
import { useElementSize } from '../../../../../hooks/useElementSize'
import { useScrollDownAnimate } from '../../../../../hooks/useScrollDownAnimate'
import { isServer } from '../../../../../helpers/isServer'
import { useContext, useEffect, useState } from 'react'
import { getAnimation } from '../helpers/getAnimation'

export const useHeader = ({ type, onHeight, data }) => {
  const { CSObject, setAnimation, showCart, setShowCart } =
    useContext(CartStoreContext)

  const [squareRef, { height }] = useElementSize()
  const [animateHeader] = useScrollDownAnimate({
    thresholdHeight: height,
    top: type === 'transparent' ? height : 0,
  })
  const [oldCSObject, setOldCSObject] = useState(null)

  const anime = getAnimation({
    value: type,
    animateHeader: isServer
      ? false
      : window?.header_link_hax_show
      ? false
      : animateHeader,
    height,
    bg: data?.layoutData?.header_background_color,
  })

  useEffect(() => {
    onHeight(height)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [height])

  useEffect(() => {
    if (CSObject) {
      if (oldCSObject == null) {
        // Checks if totalQuantity goes from null to quantity and makes sure the animation doesnt trigger then (on first page load)
        setOldCSObject(true)
      } else {
        if (CSObject?.selection?.totals?.totalQuantity > 0) {
          if (data?.settingsData?.add_to_cart_animation === 'jumping') {
            setAnimation(true)
            setTimeout(() => {
              setAnimation(false)
            }, 4000)
          } else if (
            data?.settingsData?.add_to_cart_animation === 'flyout' &&
            !window.dontShowFlyout // 🫶
          ) {
            setShowCart(true)
          }
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [CSObject?.selection?.totals?.totalQuantity])

  return [squareRef, height, anime, showCart, setShowCart, animateHeader]
}
