import { TransparentHeaderAnimation } from './headers/TransparentHeaderAnimation'
import { DefaultHeaderAnimation } from './headers/DefaultHeaderAnimation'

export const Headers = ({ data, onHeight }) => {
  if (
    data?.layoutData?.header_animation?.[0]?.component ===
    'default_header_animation'
  ) {
    return (
      <DefaultHeaderAnimation
        data={data}
        onHeight={onHeight}
        type={data?.layoutData?.header_animation?.[0]?.type}
      />
    )
  } else if (
    data?.layoutData?.header_animation?.[0]?.component ===
    'transparent_header_animation'
  ) {
    return (
      <TransparentHeaderAnimation
        data={data}
        onHeight={onHeight}
        blok={data?.layoutData?.header_animation?.[0]}
        type={'transparent'}
      />
    )
  }

  return (
    <DefaultHeaderAnimation data={data} onHeight={onHeight} type={'position'} />
  )
}
