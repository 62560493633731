import { useContext, useState, useEffect, useRef } from 'react'
import { CartStoreContext } from '../../../store/cartStore'
import { Button } from '../../atoms/Button'
import { observer } from 'mobx-react'
import classNames from 'classnames'
import { SideModal } from '../../sharable/modals/SideModal'
import { QuantityChooser } from '../../atoms/QuantityChooser'
import Link from 'next/link'
import { getProductUri } from '../../../helpers/getProductUri'
import { getProductImage } from '../../../helpers/getProductImage'
import { triggerViewCart } from '../../../lib/analytics/events'
import { Loading } from '../../atoms/Loading'
import { getProductCartDescription } from '../../../helpers/getProductCartDescription'
import { UpsellCart } from './UpsellCart'
import { Findify } from '../../bloks/Findify'
import { getText } from '../../../helpers/getText'
import { toJS } from 'mobx'

export const Cart = observer(({ show, onClose, settingsData, data }) => {
  const { CSObject } = useContext(CartStoreContext)
  const backgroundColor = data?.layoutData?.cart_background_color
  const textColor = data?.layoutData?.cart_text_color

  const hasUnAvailable = (item) => {
    return CSObject?.unavailable?.find((i) => i.item === item.item)
  }

  useEffect(() => {
    // Trigger view cart event
    if (show && CSObject?.selection?.items?.length > 0) {
      triggerViewCart({ CSObject, settingsData: settingsData })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show])

  return (
    <SideModal
      onClose={onClose}
      show={show}
      title={getText(settingsData?.cart_title, data)}
      backgroundColor={backgroundColor}
      textColor={textColor}
    >
      <div className={'h-[calc(100%-240px)] overflow-auto scrollbar-thin'}>
        {CSObject?.selection?.items.length > 0 ? (
          <div className={'h-full'}>
            <div className="flow-root">
              <ul role="list" className="-my-6 divide-y">
                {CSObject?.selection?.items?.map((item, index) => (
                  <CartItem
                    item={item}
                    settingsData={settingsData}
                    key={item?.item}
                    showUnAvailableAnimation={hasUnAvailable(item)}
                    uri={getProductUri(item?.product?.uri)}
                    textColor={textColor}
                    onClose={onClose}
                  />
                ))}
              </ul>

              {data?.layoutData?.cart_upsell?.length > 0 &&
                data?.layoutData?.cart_upsell[0].component ===
                  'upsell_cart' && (
                  <div className={'mt-6'}>
                    <UpsellCart
                      blok={data?.layoutData?.cart_upsell[0]}
                      data={data}
                      onAdd={() => {
                        onClose()
                      }}
                    />
                  </div>
                )}
              {data?.layoutData?.cart_upsell?.length > 0 &&
                data?.layoutData?.cart_upsell[0].component === 'findify' && (
                  <div className={'mt-6'}>
                    <Findify
                      blok={data?.layoutData?.cart_upsell[0]}
                      data={data}
                    />
                  </div>
                )}
            </div>

            {CSObject?.selection?.discounts?.automaticDiscounts?.length > 0 && (
              <div className={'border-t mt-6 pt-6 text-sm'}>
                {CSObject?.selection?.discounts?.automaticDiscounts?.map(
                  (autoDiscount, index) => {
                    return (
                      <div
                        key={index}
                        className={'flex justify-between items-center'}
                      >
                        <div>{autoDiscount.name}</div>
                        <div>{autoDiscount.priceOff}</div>
                      </div>
                    )
                  },
                )}
              </div>
            )}

            <div className="py-6 px-6 absolute left-0 right-0 bottom-0">
              <CartSummary
                CSObject={CSObject}
                data={data}
                settingsData={settingsData}
                onCartClose={() => onClose(false)}
                productsTotalTitle={getText(
                  settingsData?.cart_product_total,
                  data,
                )}
                textColor={textColor}
              />
            </div>
          </div>
        ) : (
          <p style={{ color: textColor }}>
            {getText(settingsData?.cart_empty_title, data)}
          </p>
        )}
      </div>
    </SideModal>
  )
})

const CartSummary = ({
  CSObject,
  settingsData,
  layoutData,
  onCartClose,
  productsTotalTitle,
  textColor,
  data,
}) => {
  return (
    <div style={{ color: textColor }}>
      <div className="flex justify-between text-base font-medium">
        <p>{productsTotalTitle}</p>
        <p>{CSObject?.selection?.totals?.itemsTotalPrice}</p>
      </div>
      <p className="mt-0.5 text-sm">{getText(settingsData?.cart_info, data)}</p>
      <div className="mt-6">
        <Link href={'/checkout'}>
          <a>
            <Button
              id={'v95-go-to-checkout-button'}
              className={'w-full'}
              onClick={onCartClose}
              variant={layoutData?.cart_button_style}
            >
              {getText(settingsData?.cart_go_to_checkout_button_title, data)}
            </Button>
          </a>
        </Link>
      </div>
      <div className="mt-6 flex justify-center text-sm text-center text-gray-500">
        <p>
          <button
            style={{ color: textColor }}
            type="button"
            className="text-black font-medium hover:text-gray-500"
            onClick={onCartClose}
          >
            {getText(settingsData?.cart_continue_shopping_button_title, data)}
            <span aria-hidden="true"> &rarr;</span>
          </button>
        </p>
      </div>
    </div>
  )
}

const CartItem = ({
  item,
  settingsData,
  showUnAvailableAnimation,
  uri,
  textColor,
  onClose,
}) => {
  const [removeLoading, setRemoveLoading] = useState(false)
  const { removeFromCart } = useContext(CartStoreContext)
  return (
    <li className={classNames('py-6 flex')} style={{ color: textColor }}>
      <div className="flex-shrink-0 w-24 h-24 overflow-hidden">
        <Link href={uri}>
          <a onClick={onClose}>
            {/* eslint-disable-next-line @next/next/no-img-element */}
            <img
              src={getProductImage(item?.product)}
              alt={getProductCartDescription(item)}
            />
          </a>
        </Link>
      </div>

      <div className="ml-4 flex-1 flex flex-col ">
        <div>
          <div className="flex justify-between text-xs lg:text-sm flex-col">
            <Link href={uri}>
              <a onClick={onClose}>
                <p style={{ wordBreak: 'break-word' }}>
                  {getProductCartDescription(item)}
                </p>
              </a>
            </Link>
            <p>
              {item?.priceEach}
              {item?.priceEachBeforeDiscount !== item?.priceEach && (
                <span className="line-through ml-2 text-gray-400 text-xs">
                  {item?.priceEachBeforeDiscount}
                </span>
              )}
            </p>
          </div>
          <p className="mt-1 text-sm text-gray-500">{item?.color}</p>
        </div>
        <div className="flex-1 flex items-end justify-between text-sm flex-wrap">
          <div
            className={classNames(
              'text-gray-500',
              showUnAvailableAnimation ? 'animate-bounce-stop-delay' : '',
            )}
          >
            <QuantityChooser item={item} />
          </div>

          <div className="flex items-center">
            {removeLoading && <Loading className={'h-3 w-3 mr-2'} />}

            <button
              style={{ color: textColor }}
              type="button"
              className="text-black hover:text-gray-500 text-xs lg:text-sm h-8"
              onClick={async () => {
                setRemoveLoading(true)
                await removeFromCart({
                  item,
                })
              }}
              disabled={removeLoading}
            >
              {settingsData?.cart_remove_title}
            </button>
          </div>
        </div>
      </div>
    </li>
  )
}
