import { useCallback, useEffect, useState } from 'react'

export const useScrollDownAnimate = ({ thresholdHeight = 60, top = 0 }) => {
  const [animateHeader, setAnimateHeader] = useState(false)
  const [y, setY] = useState(0)

  const handleNavigation = useCallback(
    (e) => {
      if (y > window.scrollY && top === 0) {
        // SCROLL UP
        setAnimateHeader(false)
      } else if (top > 0 && y < top) {
        // If top is greater then 0 we know we dont want to
        // have the scroll up animation
        // only when we are at the top 🫶
        setAnimateHeader(false)
      } else if (y > thresholdHeight && y < window.scrollY) {
        // SCROLL DOWN
        setAnimateHeader(true)
      }
      setY(window.scrollY)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [y, thresholdHeight],
  )

  useEffect(() => {
    window.addEventListener('scroll', handleNavigation)

    return () => {
      window.removeEventListener('scroll', handleNavigation)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [handleNavigation])

  return [animateHeader]
}
