import { CartStoreContext } from '../../store/cartStore'
import { MinusIcon, PlusIcon } from '@heroicons/react/solid'
import { useContext, useState } from 'react'
import { Loading } from './Loading'

export const QuantityChooser = ({ item }) => {
  const [loading, setLoading] = useState(false)
  const { setQuantity } = useContext(CartStoreContext)

  return (
    <div style={{ height: '32px', width: '92px' }} className={'border flex'}>
      <div
        onClick={async () => {
          setLoading(true)
          setQuantity({
            newQuantity: item?.quantity - 1,
            item,
          }).finally(() => {
            if (item?.quantity > 1) {
              setLoading(false)
            }
          })
        }}
        className={'flex-1 flex justify-center items-center cursor-pointer'}
      >
        <MinusIcon
          style={{ height: '12px', width: '12px' }}
          className={'font-light text-sm'}
        />
      </div>
      <div className={'flex-1 flex justify-center items-center'}>
        {loading ? (
          <Loading />
        ) : (
          <p className={'font-light text-sm'}>{item?.quantity ?? 1}</p>
        )}
      </div>
      <div
        onClick={() => {
          setLoading(true)
          setQuantity({
            newQuantity: item?.quantity + 1,
            item,
          }).finally(() => {
            setLoading(false)
          })
        }}
        className={'flex-1 flex justify-center items-center cursor-pointer'}
      >
        <PlusIcon
          style={{ height: '12px', width: '12px' }}
          className={'font-light text-sm'}
        />
      </div>
    </div>
  )
}
